@import "swiper/css";
@import "swiper/css/navigation";
@import "swiper/css/pagination";
@import "swiper/css/scrollbar";
@import "magnific-popup.min.css";
/* @import "bootstrap.min.css"; */
@import "./assetsFiles/css/bootstrap.min.css";
@import "./assetsFiles/css/style.min.css";
@import "./assetsFiles/css/fontawesome-5.14.0.min.css";
@import "./assetsFiles/css/nice-select.min.css";
@import "./assetsFiles/css/animate.min.css";
@import "./assetsFiles/css/slick.min.css";
@import "./assetsFiles/css/flaticon.min.css";




.globle-heading{
  position: relative;
}
.globle-heading img{
  position: absolute;
  top: -20px;
  right: 10vw !important;
}


.main-menu .navbar-collapse li ul {
  transition: 0.3s all;
  display: unset;
  left: 0;
  top: calc(100% + 20px);
  visibility: hidden;
  opacity: 0;
}
.main-menu .navbar-collapse .mobile-menu li ul {
  opacity: 1 !important;
  visibility: unset !important;
}
.main-menu .navbar-collapse li:hover > ul {
  visibility: visible;
  opacity: 1;
  top: 100%;
}
.main-menu .navbar-collapse li ul li ul {
  top: -2% !important;
}
.thumb-images .nav-link {
  padding: 0;
}

.testimonial-two-dots .slick-dots .swiper-pagination-bullet-active,
.testimonial-three-dots .slick-dots .swiper-pagination-bullet-active {
  background: #55e6a5;
}
.testimonial-three-dots .slick-dots .swiper-pagination-bullet {
  margin-left: 7px;
  margin-right: 7px;
}
.slick-dots .swiper-pagination-bullet {
  position: relative;
  cursor: pointer;
  width: 7px;
  height: 7px;
  transition: 0.5s;
  margin: 0 10px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.65);
}
.testimonial-two-part .slick-dots {
  display: flex !important;
  margin: 0 auto;
  text-align: center;
  margin-top: 100px;
}
.testimonial-two-part .slick-dots li.slick-active,
.testimonial-three-dots .slick-dots li.slick-active {
  background: var(--techquisite-primary-color);
}
.work-gallery-active .project-item {
  margin: 0;
  margin-bottom: 60px;
}
.swiper-pagination-bullet {
  margin: 0 10px !important;
}
.service-about-image {
  position: relative;
}
.testimonial-item {
  padding: 30px 0 0;
}

.work-gallery-controls {
  width: 350px;
  justify-content: center;
  margin: 0 auto;
}
/* .project-item {
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding: 15px !important;
} */


.services-d-m{
  display: block;
    opacity: 1;
    position: relative;
    text-transform: capitalize;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
}

@media screen and (max-width: 600px){
  .layout-set {
    min-width: 412px;
    max-width: 412px;
    min-height: 200px;
    max-height: 200px;
  }
}

/* Style for the container with the scroll */
.list-style-one {
  /* Your existing styles */
  minHeight: '20rem';
  maxHeight: '20rem';
  overflowY: 'scroll';

  /* Styles for making the scrollbar transparent */
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: transparent transparent; /* For Firefox: thumb and track color */

  /* For Chrome, Edge, and Safari */
  &::-webkit-scrollbar {
    width: 7px; /* width of the entire scrollbar */
  }

  &::-webkit-scrollbar-track {
    background: transparent; /* color of the tracking area */
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--techquisite-primary-color); /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
    border: 3px solid transparent; /* creates padding around scroll thumb */
  }
}

/* saliq */
.cntforms {
  transition-timing-function: ease-in;
  transition-duration: 300ms;
  border-bottom: 4px solid #f00;
  background-color: transparent;
  color: white;
  border-left: none;
  border-right: none;
  border-top: none;
}
.txtclrcontact {
  color: #f00;
}

.txtfieldscontact {
  background-color: transparent;
  color: white;
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: 4px solid var(--techquisite-primary-color);
}
.txtfieldscontacthome {
  background-color: #0A1019;
  color: #9CA3AF;
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: none;
  padding: 4px 14px;
  border-radius: 8px;
}
.cntforms3 {
  transition-timing-function: ease-in;
  transition-duration: 300ms;
  background-color: #0A1019;
  color: #9CA3AF;
  border: 1px solid #f00;
  padding: 4px 14px;

}
.txtfieldscontact2 {
  background-color: transparent;
  color: white;
}
.cntforms2 {
  transition-timing-function: ease-in;
  transition-duration: 300ms;
  border: 1px solid #f00;
  background-color: transparent;
  color: white;

}
.mrgnt {
  margin-top: 80px;
}
.fontzz {
  display: flex;
  gap: 2.2rem;
}

.lexendfont {
  font-family: "Lexend Zetta", sans-serif;
}

.custom-button1 {
  background-color: #6EBEAC;
  color: black;
  padding-top: 8px;
  padding-left: 4px;
  padding-right: 15px;
  white-space: nowrap;
  padding-bottom: 8px;
  width: 100%;
  border: 2px solid #6EBEAC;
  border-radius: 8px;
  margin-bottom: 30px;
  margin-top: 8px;
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
}

.custom-button1:hover {
  background-color: transparent;
  color: #6EBEAC;
  border-color: transparent;
  border: 2px solid #6EBEAC;

}
.custom-button3 {
  background-color: #6EBEAC;
  color: black;

  padding-left: 4px;
  padding-right: 8px;
  white-space: nowrap;
  width: 100%;
  border: 2px solid #6EBEAC;
  border-radius: 8px;
  margin-bottom: 30px;
  margin-top: 8px;
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
}
.custom-button3:hover {
  background-color: transparent;
  color: #6EBEAC;
  border-color: transparent;
  border: 2px solid #6EBEAC;

}
.custom-button2 {
  background-color: transparent;
  color: #6EBEAC;
  padding-top: 8px;
  padding-left: 8px;
  padding-right: 8px;
  white-space: nowrap;
  padding-bottom: 8px;
  width: 100%;
  border: 2px solid #6EBEAC;
  border-radius: 8px;
  margin-bottom: 30px;
  margin-top: 8px;
  transition: background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease;
}
.custom-button2:hover {
  background-color: #6EBEAC;
  color: black;
  border-color: transparent;
}
.bold-text {
  font-weight: bold;
  font-size: 55px;
  line-height: 55px;
  width: 100%;
}
.text-large {
  font-size: 35px;
  line-height: 35px;
}
.align-icon {
  vertical-align: middle;
  margin-left: 10px; /* Optional, adjust based on spacing */
}
@media (max-width: 991.98px) {
  .center-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .center-mobile .row {
    justify-content: center;
  }
  .bold-text {
    font-weight: bold;
    font-size: 40px;
    line-height: 60px;
    width: 100%;
  }
  .text-large {
    font-size: 35px;
    text-align: center;
  }
}

